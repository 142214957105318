section#contact-phones {
  text-align: center;
  color: $white;

  .city {
    font-size: 120%;
  }
  .number a {
      color: $white;
      font-size: 250%;
      font-weight: 500;
      line-height: 110%;
  }

  address {
    font-size: 90%;
    a {
      color: #ffffff;
    }
  }

  .email-address a {
    color: #ffffff;
    font-size: 150%;
  }
}
