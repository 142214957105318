/* Parallax Styles */

.parallax {
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed !important;
}

.parallax-overlay {
  background-color:rgba(0,0,0,0.80);
}
