/*  Dropcaps  */

.dropcap-normal {
	font-family: 'Montserrat', sans-serif;
	float: left;
	font-size: 70px;
	font-weight: bold;
	line-height: 60px;
	margin: 0 8px 0 0;
}

.dropcap.dark {
	color: #FFFFFF;
}

.dropcap.round {
	border-radius: 3px;
}

.dropcap.square {
	border-radius: 50px;
}

.dropcap {
	font-family: 'Montserrat', sans-serif;
	display: block;
	float: left;
	font-size: 36px;
	margin: 6px 8px 0 0;
	text-align: center;
	text-transform: uppercase;
	line-height:50px;
	width:50px;
	height:50px;
}