section#team-detail {

  .left {
    display: inline-block;
    width: 40%;
    @media only screen and (max-width: $vPhone) {
      width: 100%;
    }
  }
  .right {
    display: inline-block;
    width: 55%;
    padding-left: 2em;
    @media only screen and (max-width: $vPhone) {
      width: 100%;
    }
  }

  p {
    margin-bottom: 0.8em;
  }

  @media only screen and (max-width: $tablet) {
    .container {
      width: 94%;
      margin: 0 3%;
    }
  }

  @media only screen and (max-width: $hPhone) {}
  @media only screen and (max-width: $vPhone) {}

}
