
/* Portfolio */

.container.portfolio-title {
	padding-bottom:0px;
}

#portfolio {
	padding-bottom: 0;
}



/* Portfolio Filters */

#filters {
	margin-top: 10px;
	margin-bottom: 40px;
	text-align: center;
	display: block;
	float: none;
	z-index: 2;
	position: relative;
}

#filters ul li {
	display: inline-block;
	margin:0 5px;
}

#filters ul li a {
	display: block;
}

#filters ul li a h5 {
	padding: 8px 15px;
	text-transform:uppercase;
	background-color:#eeeeee;
	border-radius:3px;
	font-size:10px;
	transition:all 0.3s ease 0s;
	-moz-transition:all 0.3s ease 0s;
	-webkit-transition:all 0.3s ease 0s;
	-o-transition:all 0.3s ease 0s;
}

#filters ul li a:hover h5, #filters ul li a.active h5 {
	color: #fff;
}



/* Portfolio Items */

#portfolio-wrap {
	position: relative;
	padding: 0;
	width: 100%;
	margin: 0 auto;
	display: block;
}

#portfolio-wrap .one-four {
	margin: 0!important;
	line-height:0;
	width: 25%;
	padding: 0;
	position:relative;
}

.portfolio-item {
	padding: 0;
	position: relative;
	overflow: hidden;
}
.portfolio-item .portfolio-image {
	overflow: hidden;
	display: block;
	position: relative;
}
.portfolio-item .portfolio-image img {
	width: 100%;
	height: auto;
}

.portfolio-item:hover .project-overlay {
	opacity:1;
	transition:all 0.4s ease 0s;
	-moz-transition:all 0.4s ease 0s;
  	-webkit-transition:all 0.4s ease 0s;
  	-ms-transition:all 0.4s ease 0s;
	-o-transition:all 0.4s ease 0s;
}

.project-overlay {
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	left:0;
	opacity:0;
	transition:all 0.3s ease 0s;
	-moz-transition:all 0.3s ease 0s;
  	-webkit-transition:all 0.3s ease 0s;
  	-o-transition:all 0.3s ease 0s;
	cursor:pointer;
}

.open-project-link {
	width:100%;
	height:100%;
	position:absolute;
	z-index:100;
}

.project-info {
	position:absolute;
	height:100px;
	width:100%;
	top:50%;
	margin-top:-50px;
	text-align:center;
}

.project-name {
	color:#fff;
	text-transform:uppercase;
	margin-top:50px;
	opacity:0;
	transition:all 0.2s ease 0s;
	-moz-transition:all 0.2s ease 0s;
  	-webkit-transition:all 0.2s ease 0s;
  	-ms-transition:all 0.2s ease 0s;
	-o-transition:all 0.2s ease 0s;
}

.portfolio-item:hover .project-name {
	opacity:1;
	-webkit-transition: opacity .4s .5s;
	-moz-transition: opacity .4s .5s;
	-ms-transition: opacity .4s .5s;
	-o-transition: opacity .4s .5s;
	transition: opacity .3s .5s;
}

.project-categories {
	font-size:13px;
	color:#fff;
	opacity:0;
	margin-top:13px;
	transition:all 0.2s ease 0s;
	-moz-transition:all 0.2s ease 0s;
  	-webkit-transition:all 0.2s ease 0s;
  	-ms-transition:all 0.2s ease 0s;
	-o-transition:all 0.2s ease 0s;

}

.portfolio-item:hover .project-categories {
	opacity:0.5;
	-webkit-transition: opacity .4s .8s;
	-moz-transition: opacity .4s .8s;
	-ms-transition: opacity .4s .8s;
	-o-transition: opacity .3s .8s;
	transition: opacity .3s .8s;
}

.zoom-icon {
	left: 50%;
	margin-left:-20px;
	position:inherit;
	width:40px;
	height:40px;
	margin-top:40px;
	opacity:0;
	background:url(images/zoom.png);
	background-size:40px 40px;
	transition:all 0.3s ease 0.1s;
	-moz-transition:all 0.3s ease 0.2s;
  	-webkit-transition:all 0.3s ease 0.2s;
  	-ms-transition:all 0.3s ease 0.2s;
	-o-transition:all 0.3s ease 0.2s;
}

.portfolio-item:hover .zoom-icon {
	opacity:1;
	margin-top:0px;
	-webkit-transition: opacity .3s .2s, margin-top .3s .2s;
	-moz-transition: opacity .3s .2s, margin-top .3s .2s;
	-ms-transition: opacity .3s .2s, margin-top .3s .2s;
	-o-transition: opacity .3s .2s, margin-top .3s .2s;
	transition: opacity .3s .2s, margin-top .3s .2s;

}




/* Portfolio Isotope Transitions */

.isotope, .isotope .isotope-item {
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
	transition-duration: 0.8s;
}

.isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}

.isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-ms-transition-property: -ms-transform, opacity;
	-o-transition-property: -o-transform, opacity;
	transition-property: transform, opacity;
}

.isotope.no-transition, .isotope.no-transition .isotope-item, .isotope .isotope-item.no-transition {
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}



/* Portfolio Expander */



#project-page-holder {
	display: none;
	background-color:#FFF;
}

#project-page-button-fullscreen {
	height: auto;
	z-index:100;
	position: absolute;
	right:30px;
	top:30px;
}

#project-page-button-fullscreen li {
	display:inline-block;
	font-size:30px;
}

#project-page-button-fullscreen li a {
	background:url(images/close.png) no-repeat #fff;
	background-size:50px 50px;
	width:50px;
	height:50px;
	display:block;
	border-radius:50px;
	opacity:0.5;
}

#project-page-button-fullscreen li a:hover {
	opacity:1;
}

#project-page-button {
	text-align:center;
	margin-bottom:20px;
	height: auto;
	position: relative;
	z-index:100;
}

#project-page-button li {
	display:inline-block;
	margin:60px 10px 0px 10px;
	font-size:30px;
}

#project-page-data {
	margin-bottom: 0px;
}

.project-section-title {
	text-align:center;
	margin-bottom:40px;
}

.project-section-title h1 {
	margin-bottom:5px!important;
	letter-spacing:1px;
}

.project-page {
	background-color:#FFF;
	width:100%;
	height:auto;
	left:0;
	padding-bottom:40px;
}


/* Portfolio Normal Expander Project Slider */


.project-image-slider .bx-wrapper .bx-next {
	background: url("images/project_slider_right.png") no-repeat;
	background-size:35px 65px;
	opacity:0.3;
	right:-90px;
}

.project-image-slider .bx-wrapper .bx-prev {
	background: url("images/project_slider_left.png") no-repeat ;
	background-size:35px 65px;
	opacity:0.3;
	left:-90px;
}

.project-image-slider .bx-wrapper .bx-next:hover, .project-image-slider .bx-wrapper .bx-prev:hover {
	opacity:1;
	background-position:0
}

.project-image-slider .bx-wrapper .bx-controls-direction a {
	height: 65px;
	margin-top: -33px;
	outline: 0 none;
	position: absolute;
	text-indent: -9999px;
	top: 50%;
	width: 35px;
	z-index: 100;
}

/* Portfolio Normal Expander */

.project-description {
	margin-top:40px;
	margin-bottom:20px;
	width:100%;
	height:auto;
}

.small-border {
	height:2px;
	width:50px;
	background-color:#ddd;
	margin-bottom:15px;
}

.visit-project {
	width:100%;
	text-align:center;
	border-top:1px solid #ddd;
	margin-top:20px;
	padding:55px 0 20px 0;
}

.project-description h5 {
	color:#222222;
	text-transform:uppercase;
	margin-bottom:5px;
}



/* Portfolio FullScreen Expander */

#cycle-loader {
	height:32px;
	left:50%;
	margin:-8px 0 0 -8px;
	position:absolute;
	top:50%;
	width:32px;
	z-index:999;
}

#arrow_left {
	top:50%;
	height:60px;
	position:absolute;
	left:30px;
	width:32px;
	z-index:1000;
	margin-top:-30px;
}

#arrow_right {
	top:50%;
	height:60px;
	position:absolute;
	right:30px;
	width:32px;
	z-index:1000;
	margin-top:-30px;
}

#arrow_left:hover, #arrow_right:hover {
	margin-top:-28px;
}

#arrow_left:active, #arrow_right:active {
	margin-top:-28px;
}

div.mc-image {
	-webkit-transition: opacity 1s ease-in-out;
	-moz-transition: opacity 1s ease-in-out;
	-o-transition: opacity 1s ease-in-out;
	transition: opacity 1s ease-in-out;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position:center center;
	background-repeat:no-repeat;
	height:100%;
	overflow:hidden;
	width:100%;
}

.in-slide-content div.info-slide {
	background:#FFF url(images/info.png) no-repeat left bottom;
	background-size:46px 46px;
	position:absolute;
	top:30px;
	left:30px;
	width: 46px;
	height: 46px;
	margin: 0;
	display: block;
	text-indent: 10px;
	-moz-border-radius: 23px;
	-webkit-border-radius: 23px;
	border-radius: 23px;
	cursor: pointer;
	z-index:1000;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=50)";
	filter: alpha(opacity=0.5);
	opacity: 0.5;
	-webkit-transition: all 0.3s ;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
}

.in-slide-content div.info-slide:hover {
	width:200px;
	background:#FFF;
	position:absolute;
	bottom:40px;
	padding:10px 20px;
	text-indent: 0px;
	moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	z-index:1000;
	-ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: alpha(opacity=1);
	opacity: 1;
}

.in-slide-content h2, .in-slide-content p{
	padding: 0;
	margin: 0;
	font-size: 12px;
	line-height:24px;
}

.in-slide-content h2{
	font-size:14px;
	color:#444;
	text-shadow:none;
	font-weight:normal;
	text-transform:uppercase;
	z-index:1001;
	position:relative;
	white-space: nowrap;
}

.info-slide p{
	color:#aaa;
	font-size:13px;
	text-shadow:none;
	z-index:1001;
	position:relative;
	margin:0;
	font-weight:normal;
	white-space: nowrap;

}

.in-slide-content div.info-slide h2 {
	display:none;
	padding:10px 10px 0 10px;
}

.in-slide-content div.info-slide p {
	display:none;
	padding:0px 10px 10px 10px;
}

.in-slide-content div.info-slide:hover h2, .in-slide-content div.info-slide:hover p {
	display:inherit;
	padding:0;
}