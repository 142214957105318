/*  Tabs  */

.tab-shortcode {
	float:left;
	width:100%;
}

ul.tabs {
	overflow: hidden;
	margin: 0px;
}

ul.tabs li {
	float: left;
	margin: 0px;
	list-style: none;
}

ul.tabs li a {
	display: block;
	background-color: #e5e5e5;
	padding: 6px 20px;
	margin-right: 5px;
	border-radius: 2px 2px 0px 0px;
	color: #666;
	text-decoration: none;
	font-size:13px;
}

ul.tabs .tab-active a {
	color: #fff;
}

.tab_container {
	clear: both;
	padding: 20px 25px;
	margin-top:0px;
	background:#FFF;
	font-size:13px;
}