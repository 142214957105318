/* Our Team */

section#team {
  padding-bottom: 0;

  .our-team {
    text-align:center;
  }

  .our-team li {
    display:inline-block;
    margin:0 50px;
    height:330px;
    overflow:hidden;
    border-radius:3px;
    position:relative;
    width:260px;

    @media only screen and (max-width: $vPhone) {
      margin: 0 0;
    }
  }

  .our-team li img {
    display:block;
    border-radius:3px;
    position:relative;
    z-index:0;
    float:left;
  }

  .team-profile {
    height:200px;
    padding:40px 20px 20px 20px;
    margin-top:235px;
    z-index:11;
    position:absolute;
    border-radius:3px;
    transition:all 0.4s ease 0s;
    -moz-transition:all 0.4s ease 0s;
      -webkit-transition:all 0.4s ease 0s;
      -o-transition:all 0.4s ease 0s;
  }

//  .our-team li:hover > .team-profile {
  .our-team li > .team-profile {
    margin-top:0px;
    background-color:#FFF;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
  }

  .team-profile h5 {
    color:#222222;
    text-transform:uppercase;
  }

  p.about-team {
    margin:20px 0 30px 0;
    font-size:13px;
  }

  ul.more {
    position: absolute;
    bottom: 30px;
    height: 20px;
    width: 220px;
    text-align:center;
  }

  ul.more li {
    width:90px;
    height:35px;
    margin:5px;
    display:inline-block
  }

  ul.more li a {
    opacity:0.5;
    transition:all 0.3s ease 0s;
    -moz-transition:all 0.3s ease 0s;
      -webkit-transition:all 0.3s ease 0s;
      -o-transition:all 0.3s ease 0s;
  }

  ul.socials-icons li a:hover {
    opacity:1;
  }

  .team-assitants {
    p {
      margin-top: 2em;
      width: 220px;
    }
  }
}
