/* Twitter Parallax*/

.twitter-feed .bx-wrapper {
  margin:30px 0 15px 0!important;
}

.twitter-feed li, .twitter-feed li a {
  color:#fff!important;
  text-transform:none;
  font-size: 17.5px;
  font-family: 'Montserrat',sans-serif;
    font-weight: 400;
}

.twitter-feed p {
  opacity:0.5;
}

.twitter-feed-icon img {
  width:60px;
  height:auto;
  opacity:0.7;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

.twitter-feed-icon img:hover {
  opacity:1;
}

.twitter-feed .bx-wrapper .bx-controls-direction a {
  width: 18px;
  height: 34px;
}

.twitter-feed .bx-wrapper .bx-prev {
  left: 50%;
  margin-left:-30px;
  margin-top:90px;
  background: url(images/slider_left_white.png) no-repeat;
  background-size:18px 34px;
  opacity:0.5;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
  z-index:0;
}


.twitter-feed .bx-wrapper .bx-next {
  right: 50%;
  margin-right:-30px;
  margin-top:90px;
  background: url(images/slider_right_white.png) no-repeat;
  background-size:18px 34px;
  opacity:0.5;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
  z-index:0;
}

.twitter-feed .bx-wrapper .bx-prev:hover, .twitter-feed .bx-wrapper .bx-next:hover {
  opacity:1;
}

.twitter-feed .bx-wrapper .bx-next:hover {
  background-position:0 0;
}

.twitter-feed {
  margin-bottom:70px;
}
