/*  Font Awesome  */

.the-icons li {
  font-size:14px;
  height: 40px;
  text-transform: capitalize;
}

.the-icons li i {
  font-size: 18px;
  margin-right: 10px;
  vertical-align: -2px;
  width: 35px;
}
