#newsletter .container {
	padding-top:30px;
	padding-bottom:30px;
}

.newsletter-left {
	width:60%;
	float:left;
	height:50px
}

.newsletter-right {
	width:40%;
	float:left;
	text-align:right;
	height:40px;
	padding-top:10px;
}

#newsletter input[type="text"] {
	border: 0 none;
	color: #fff;
	font-family: 'Montserrat',sans-serif;
	font-size: 36px;
	line-height:50px;
	margin:0;
	height: 50px;
	padding:0px;
	text-transform: uppercase;
	width: 100%;
}