@mixin sfo-section-shade($height) {
  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: $height;
    left: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.5) 60%, rgba(0,0,0,0));

    @media only screen and (max-width: $vPhone) {
      height: 2 * $height;
    }
  }
}
