/*  Buttons  */



a.newave-button {
	border-radius:3px;
	font-family: 'Montserrat', sans-serif;
	letter-spacing:0;
	text-transform:uppercase;
	display:inline-block;
}

.small {
	font-size:12px;
	line-height:14px;
	padding:5px 9px 7px 9px;
	line-height:12px;
}

.medium {
	font-size:14px;
	line-height:14px;
	padding:10px 15px;
	line-height:14px;
}

.large {
	font-size:16px;
	line-height:14px;
	padding:15px 25px;
	line-height:16px
}

.grey {
	color:#FFF;
}

.grey:hover {
	color:#FFF;
}

.color {
	color:#fff;
}

.color:hover {
	color:#fff;
}

.outline {
	background-color:none;
	border:2px solid #222222;
	color:#222222;
	line-height:10px!important;
}

.outline:hover {
	background-color:#222222;
	color:#FFF;
}

.outline.white {
	background-color:none;
	border:2px solid #fff;
	color:#fff;
}

.outline.white:hover {
	background-color:#fff;
	color:#222;
}

.rounded {
	border-radius:50px!important;
}