/* Home */

.home__logo {
  margin-bottom: 100px;
}

/* Home Pattern And Image Parallax */

.home-pattern {
  background:url(../images/home_pattern.png) repeat;
  width:100%;
  height:100%;
}

.pattern {
  background:url(../images/pattern.png) repeat;
  width:100%;
  height:100%;
}

#home-center {
  height: 100%;
  display: table;
  position: absolute;
  top: 0;
  left:0;
  right:0;
  width:90%;
  margin-left:5%;
  margin-right:5%;
}

.div-align-center {
  display: table-cell;
  vertical-align: middle;
  text-align:center;
}

.ultralarge {
  color:#fff;
  text-align:center;
  font-family: 'Montserrat', sans-serif;
  font-size:100px;
  line-height:80px;
  letter-spacing:0;
}

.four-zero-four {
  color:#fff;
  text-align:center;
  font-family: 'Montserrat', sans-serif;
  font-size:180px;
  line-height:180px;
  letter-spacing:0;
}

.below-four-zero-four {
  margin-bottom:40px!important;
}

.text-slide-vertical {
  color:#c5c5c4;
  font-family: 'Montserrat', sans-serif;
  font-size:70px;
  text-transform:uppercase;
  width:100px!important;
  position:relative!important;

}

.text-slide-vertical li {
  line-height:100px;
}

.bullet {
  margin: 0 10px;
  opacity: 0.9;
  color:#fff;
}

.div-align-center p{
  margin-bottom:20px;
}

p.after-slider {
  font-family:Georgia, "Times New Roman", Times, serif;
  font-size:30px;
  font-style:italic;
  text-align:center;
  padding-top:20px;
  margin-bottom:40px;
}

/* Home Fullwidth Parallax Slider */

ul.fullwidth-slider {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #222222;
  z-index: 0;
  -webkit-backface-visibility: visible;
  background-position: center center;
  background-repeat: no-repeat;
}

ul.fullwidth-slider li.slide {
  position: relative;
  display: none;
  overflow: hidden;
  float: left;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-attachment:absolute ;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-backface-visibility: visible;
}

ul.fullwidth-slider li.slide .caption {
  position: relative;
  height: 100%;
  width:100%;
}

ul.fullwidth-slider li.slide .caption .caption-inside {
  position: absolute;
  top: 0px;
  width:100%;
}

ul.fullwidth-slider li.slide .caption .slide-caption {
  height: 100%;
  z-index: 0;
  display: table;
  position: relative;
  top: 0;
  width:80%;
  margin-left:10%;
    margin-right:10%;
}

ul.fullwidth-slider li.slide .caption .slide-caption .vertical-align-middle{
    display: table-cell;
    vertical-align: middle;
    text-align:center;
}

.vertical-align-middle p {
  margin:20px 0;
  color:#fff;
}

.vertical-align-middle.dark p, .vertical-align-middle.dark h1 {
  color:#222;
}

ul.fullwidth-slider, ul.fullwidth-slider .caption-inside {
  height: 700px;
}

.parallax-slider .bx-wrapper .bx-next {
  right:50px;
}

.parallax-slider .bx-wrapper .bx-prev {
  left:50px;
}

/* Home Full Screen Slider */

#fullscreen-slider {
  display:none;/* Only use this if you fade it in again after the images load */
}

#cycle-loader {
  height:32px;
  left:50%;
  margin:-8px 0 0 -8px;
  position:absolute;
  top:50%;
  width:32px;
  z-index:999;
}

#slider_left {
  top:50%;
  height:60px;
  position:absolute;
  left:50px;
  width:32px;
  z-index:1000;
  margin-top:-30px;
  cursor:pointer;
}

#slider_right {
  top:50%;
  height:60px;
  position:absolute;
  right:50px;
  width:32px;
  z-index:1000;
  margin-top:-30px;
  cursor:pointer;
}

#slider_left:hover, #slider_right:hover {
  margin-top:-28px;
}
#slider_left:active, #slider_right:active {
  margin-top:-28px;
}

.slide-content {
  height: 100%;
  display: table;
  position: absolute;
  top: 0;
  left:0;
  right:0;
  width:80%;
  margin-left:10%;
  margin-right:10%;
}

.light {
  color:#fff!important;
}

.dark {
  color:#222222;
}

.slide-content h1 {
  color:inherit;
  font-size:100px;
  line-height:100px;
  margin:10px 0;
  letter-spacing:1px;
}

.georgia {
  font-family: Georgia,"Times New Roman",Times,serif;
  font-size: 30px;
  line-height:30px;
  font-style: italic;
  padding:10px 0;
}

.slide-content p {
  margin:10px 0;
}

.slide-content a {
  margin:10px 0;
}

/* Home Full Screen Video */

.video-section {
  background-image: url('../images/video-placeholder.jpg');
}

#video-volume {
  bottom: 30px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 24px;
  margin-left: -10px;
  left:50%;
  position: absolute;
  width: 20px;
  z-index:10;
}
