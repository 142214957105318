/* Section pages styles */

section {
	position: relative;
	background-color:#f5f5f5;
	z-index: 1;
}

#home {
	height: 100%;
	position: relative;
	width: 100%;
}

#not-found {
	height: 100%;
	position: relative;
	width: 100%;
}

#home.parallax-slider {
	height:auto;
}

#we-are-good-at {
	background-color:#FFF;
}

#services {
	background-color:#FFF;
}

#we-love-technology {
	background-color:#FFF;
}

#portfolio {
	background-color:#FFF;
}

#contact {
	background-color:#fff;
}

footer {
	background-color:#fff;
}

#blog {
	background-color:#fff;
}