/* Tax */

section#tax {
  background-image: url('../images/sfo/pattern-1.png');

  @include sfo-section-shade(50%);
  @include sfo-header-bubble(110px);

  .tax-services {
    text-align:center;
  }

  > ul > li {
    margin: 0 10px;
    width: 195px;
    height: 160px;
    padding: 10px 20px 10px 20px;
    @media screen and (max-width: $vPhone) {
      margin-bottom: 130px;
    }
  }

  .tax-services-detail {
    background-color: #FFF;
    padding: 20px 20px;
    width: 710px;
    margin: 40px auto 0;

    li {
      margin-top: 6px;
      &:first {
        margin-top: 0;
      }
    }

    @media screen and (max-width: $vPhone) {
      width: 92%;
      padding: 20px 2%;
      margin: 0 2%;
    }

  }

  @media screen and (max-width: $desktop) {}
  @media screen and (max-width: $tablet) {
    .container {
      width: 100%;
    }
  }
  @media screen and (max-width: $hPhone) {}
  @media screen and (max-width: $vPhone) {}
}
