/* Clients */

#bx-pager {
  text-align:center;
}

#bx-pager a {
  border-radius:3px;
  margin:10px;
  background-color:#FFF;
  display:inline-block;
}

#bx-pager a img {
  opacity:0.5;
  border-radius:3px;
  width:200px;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

#bx-pager a img:hover, #bx-pager a.active img {
  opacity:1;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

.clients-slider li {
  padding:0 50px;
}

.clients-slider h3 {
  color:#222222;
  text-transform:uppercase;
  margin-top:20px;
  text-align:center;
}

.clients-slider p {
  margin-top:20px;
  text-align:center;
}
