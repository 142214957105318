/* Shortcode call to action */

.call-action {
  color:#222222;
  text-transform:uppercase;
  width:100%;
  text-align:center;
  padding:60px 0;
  line-height:35px;
}

.call-action a {
  margin-left:20px;
}
