section#contact-form {
  .container {
    text-align: center;

    .one-half {
      display: inline-block;
      width: 46%;
    }
    .left {
      padding-right: 1em;
      input {
        display: block;
        float: right;
        border: 1px solid #E7E7E7;
        width: 80%;
        background-color: #F5F5F5;
        box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
        -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
        -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
        height: 20px;
        padding: 4px 6px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
        color: #555555;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        vertical-align: middle;
      }
    }
    .right {
      padding-left: 1em;
      textarea {
        border: 1px solid #E7E7E7;
        background-color: #F5F5F5;
        height: 80px;
      }
    }
    .panel {
      clear: both;
      text-align: center;
    }
  }
  @media screen and (max-width: $hPhone) {
    display: none;
  }

}
