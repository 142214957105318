/*  Alert Boxes  */

.alertboxes {
	float:left;
	width:100%;
}

.shortcode_alertbox {
	border-radius: 3px;
	color: #FFFFFF;
	display: block;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 20px;
	padding: 15px 40px 15px 14px;
	position: relative;
}

.shortcode_alertbox .box_close {
	background: url("images/close_box.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
	background-position:10px 10px;
	background-size:20px 20px;
	display: block;
	height: 40px;
	position: absolute;
	right: 5px;
	top: 5px;
	width: 40px;
	cursor:pointer;
}

.shortcode_alertbox b {
	color:inherit;
}

.shortcode_alertbox.box_red {
	background: none repeat scroll 0 0 #F9E5E6;
	border: 1px solid #F7C7C9;
	color:#B3696C;
}
.shortcode_alertbox.box_blue {
	background: none repeat scroll 0 0 #D8ECF5;
	border: 1px solid #9AC9DF;
	color: #528DA9;
}
.shortcode_alertbox.box_yellow {
	background: none repeat scroll 0 0 #FCF7D9;
  	border: 1px solid #F5DC7D;
  	color: #C4A21B;
}
.shortcode_alertbox.box_green {
	background: none repeat scroll 0 0 #E3EBC6;
	border: 1px solid #C2D288;
	color: #8FA442;
}
