/* Toggle */

.toggle-wrap {
	float:left;
	width:100%;
}

.toggle-title {
	background: #e5e5e5;
	cursor: pointer;
	padding: 10px;
	border-radius: 3px;
	margin-bottom: 5px;
	display: block;
	font-size:13px;
}

.toggle-title a {
	color: #666;
	text-decoration: none;
}

.toggle-title a:hover {
	color:#222222;
}

.toggle_container {
	padding-bottom: 10px;
	padding-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
	font-size:13px;
}

.toggle-active {
	color:#fff;
	background: #222222;
}

.toggle-active a {
	color:#FFF;
}

.toggle-active a:hover {
	color:#FFF;
}