/* Contact Formular */

#contact-formular {
  text-align:center!important;
}

#contact-formular input[type="text"], textarea {
  color: #999999;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform:uppercase;
  height: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 94%;
  background:#f5f5f5;
  border-radius:3px;
  margin-bottom:20px;
  border:0;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
  -moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
  -webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
  -o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

#contact-formular input[type=text]:focus, #contact-formular textarea:focus, input[type=email]:focus {
  background-color: #e1e1e1;
}

#contact-formular textarea {
  height:160px;
  resize:none;
}

#contact-formular input[type="submit"]  {
  color:#fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform:uppercase;
  padding:10px 15px;
  letter-spacing:0;
  line-height:14px;
  cursor:pointer;
  border-radius:3px;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
  text-align:center;
}

.error_message {
  text-align:center;
  margin-bottom:30px;
  font-size:13px;
  color:#e93735;
  font-weight:bold;
}

#success_page {
  text-align:center;
}

#success_page p {
  color:#999999;
  margin-top:20px;
}

#message fieldset {
  border:none;
}

#map_canvas {
  width:100%;
  height:400px;
  z-index:0;
  position:relative;
}
