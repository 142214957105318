@mixin sfo-header-bubble($height) {

  > ul {
    margin-top: 140px;
    text-align:center;


    > li {
      display:inline-block;
      vertical-align: top;
      background-color: white;
      overflow: visible;
      text-align: left;
      border-radius:0px;
      position:relative;

      img.back {
        width: 60%;
        margin: 0px - $height auto 0;
        padding: 0 20%;
      }

      h2 {
        width: 50%;
        text-align: center;
        line-height: 120%;
        font-size: 1em;
        margin: 55px - $height auto 70px;
      }

      img.logo {
        width: 25%;
        margin: -50px - $height auto 0;
        padding: 0 38%;
      }
    }
  }
}
