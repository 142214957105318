/* Contact Parallax */

.contact-details {
  text-align:center;
  width:100%;
}

.phone-icon {
  width:60px;
  height:60px;
  margin:0 auto;
  margin-bottom:40px;
}

.company-phone a {
  color:#fff;
  background-color:rgba(255,255,255,0.15);
    text-align: center;
  font-size:72px;
  line-height:72px;
  font-family: 'Montserrat', sans-serif;
  padding:10px 20px;
  border-radius:3px;
  margin-bottom:30px;
  display:inline-block;
}

.company-phone a:hover {
  color:#fff;
}

h5.company-email {
  margin-bottom:15px;
  color:#999999;
  text-transform:none;
}

h5.company-address {
  margin-bottom:15px;
  color:#999999;
  text-transform:none;
}
