/* Pricing Tables */


.pricing-tables {
	float:left;
	width:inherit;
	display:block;
	text-align:center;
}

.price-table {
	border: 1px solid rgba(0, 0, 0, 0);
	padding: 25px 0px 25px 0px;
	text-align: center;
	transition: all 0.5s ease 0s;
	width:22%;
	display:inline-block;
	border: 1px solid #E8E8E8;
	background-color:#FFF;
	z-index:1;
	margin-left:-2px;
	margin-right:-2px;
	position:relative;
	margin-top:20px;
	margin-bottom:20px;
}

.price-table:hover {
	border: 1px solid #E8E8E8;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	z-index:100!important;
	padding: 45px 0px 45px 0px;
	margin-top:0px;
	margin-bottom:0px;

}

.price-table.featured {
	border: 1px solid #E8E8E8;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	z-index:100!important;
	padding: 45px 0px 45px 0px;
	margin-top:0px;
	margin-bottom:0px;

}

.price-table i {
	background-color: #FCFCFC;
	border: 2px solid #DDDDDD;
	border-radius: 100px;
	box-shadow: 0 0 0 5px #F4F4F4;
	display: inline-block;
	font-size: 20px;
	width:20px;
	height:20px;
	margin-bottom: 35px;
	overflow: hidden;
	padding: 25px;
	transition: all 0.2s ease 0s;
}

.price-table:hover i {
	box-shadow: 0 0 0 5px #FFFFFF inset;
	color: #FFFFFF;
}

.price-table ul {
	margin-bottom: 0;
}

.price-table li {
	border-bottom: 1px dotted #E6E6E6;
	font-size: 12px;
	padding: 8px 15px;
	margin:0px 10px;
}

.price-table li.pricetable-price {
	background-color: #F7F7F7;
	border-top: 1px dotted #E6E6E6;
	margin:0px;
}

.price-table li a {
	margin-top:20px;
}

.price-table li:first-child {
	padding-top: 0;
	border-bottom: medium none;
}

.price-table li:last-child {
	border-bottom: medium none;
}

.price-table ul h3 {
	font-size: 20px;
	line-height: 16px;
	margin-bottom: 7px;
}

.price-table li.pricetable-price h3{
	font-size: 14px;
}
