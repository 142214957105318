/* Services */

.service-item {
  float:left;
  margin:30px 0;
}

.service-item h5 {
  text-transform:uppercase;
  color:#222222;
  margin:5px 0;
}

.service-item.text-right {
  text-align:right;
}

.service-item.text-right .fa-stack {
  cursor:pointer;
  margin-left:20px;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

.service-item.text-left .fa-stack {
  cursor:pointer;
  margin-right:20px;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}



.service-item.text-right .fa-stack:hover, .service-item.text-left .fa-stack:hover {
  color:#222222;
}

.service-item .service-icon {
  font-size:27px!important;
}


