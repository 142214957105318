/* Responsive Media Querries */
@media only screen and (max-width: 1300px) {

  .container {
    width:920px;
  }

  .text-slide-vertical {
    font-size:60px;
  }

  .text-slide-vertical li {
    line-height:80px;
  }

  .small-screen-left, .small-screen-right {
    top: 100px;
    width: 300px;
  }

  .slide-content h1 {
    font-size:80px;
    line-height:80px;
  }

  .big-screen {
    width: 500px;
  }

  .our-team li {
    margin: 0 20px;
  }

  ul.technology li {
    padding: 30px 10px;
    width: 185px;
  }

  #blog .container {
    width: 920px!important;
  }

  .blog-posts-content {
    min-height: 100px;
    width: 680px;
    margin-right:40px;
  }

  .post-content {
    float: left;
    width: 600px;
  }

  #sidebar {
    width: 200px;
  }

  input#search {
    width: 140px;
  }

  .inner-navigation.masonry {
    margin: 50px 0px ;
  }

  #blog.masonry .container {
    width: 950px !important;
  }

  .blog-post.masonry {
    margin: 18px;
    width: 238px;
  }

  .blog-post.masonry-quote {
    margin: 18px;
    width: 280px;
  }

  #project-header {
    height: 500px;
  }

  #project-slider .bx-wrapper .bx-prev {
    left: -50px;
  }

  #project-slider .bx-wrapper .bx-next {
    right: -50px;
  }

  .project-page .bx-wrapper .bx-prev {
    left: -50px;
  }

  .project-page .bx-wrapper .bx-next {
    right: -50px;
  }

  #project-counters .counters {
    margin: 0 auto;
    width: 1040px;
  }
}

@media only screen and (max-width: $tablet) {


  .container {
    width:600px;
  }

  .text-slide-vertical {
    font-size:60px;
  }

  .text-slide-vertical li {
    line-height:80px;
  }

  .slide-content h1 {
    font-size:60px;
    line-height:60px;
  }

  ul.fullwidth-slider li.slide {
    background-position: center 0px!important;
  }

  ul.fullwidth-slider li.slide .caption .caption-inside {
    position: relative;
    width:100%;
    height:100%;
  }

  #slider_left {
    top:90%;
    height:60px;
    position:absolute;
    left:50px;
    width:32px;
    z-index:1000;
    margin-top:-30px;
    cursor:pointer;
  }

  #slider_right {
    top:90%;
    height:60px;
    position:absolute;
    right:50px;
    width:32px;
    z-index:1000;
    margin-top:-30px;
    cursor:pointer;
  }

  #slider_left:hover, #slider_right:hover {
    margin-top:-28px;
  }

  #slider_left:active, #slider_right:active {
    margin-top:-28px;
  }

  .ultralarge {
    font-size:80px;
    line-height:70px;
  }

  .element_from_left {
    left: 0px;
    opacity: 1;
  }

  .element_from_right {
    right: 0px;
    opacity: 1;
  }

  .element_fade_in {
    opacity: 1;
    right: 0px;
  }

  .parallax {
    background-position: center top !important;
    background-attachment: scroll !important;
    height: auto;
    margin: 0 auto;
    width: 100%;
  }

  .one_half {
      width: 100%;
  }

  .one_third {
    width: 100%;
  }

  .one_fourth {
    width: 100%;
  }

  .one_fifth {
    width: 100%;
  }

  .one_sixth {
    width: 100%;
  }

  .two_fifth {
    width: 100%;
  }

  .two_fourth {
    width: 100%;
  }

  .two_third {
    width: 100%;
  }

  .three_fifth {
    width: 100%;
  }

  .three_fourth {
    width: 100%;
  }

  .four_fifth {
    width: 100%;
  }

  .five_sixth {
    width: 100%;
  }

  .one_half, .one_third, .two_third, .three_fourth, .one_fourth, .two_fourth, .one_fifth, .two_fifth, .three_fifth, .four_fifth, .one_sixth, .five_sixth {
    margin-bottom: 20px;
    margin-right: 0%;
  }

  .section-title {
    width:90%;
  }

  .small-screen-left, .small-screen-right {
    top: 40px;
    width: 200px;
  }

  .big-screen {
    width: 320px;
  }

  .service-item .right {
    float: left;
  }

  .service-item.text-right {
    text-align: left;
  }

  .service-item.text-right .fa-stack {
    margin-left: 0px;
    margin-right: 20px;
  }

  ul.technology li {
    padding: 30px 20px;
    width: 240px;
  }

  #portfolio-wrap .one-four {
    width: 50%;
  }

  #blog .container {
    width: 600px!important;
  }

  .blog-posts-content {
    min-height: 100px;
    width: 600px;
    margin-right:40px;
  }

  .post-content {
    float: left;
    width: 520px;
  }

  #sidebar {
    width: 600px;
    border-top:#E9E9E9 solid 1px;
    margin-top:60px;
    padding-top:60px;
  }

  input#search {
    width: 540px;
  }

  #blog.masonry .container {
    width: 630px !important;
  }

  .blog-post.masonry {
    background-color: #F7F7F7;
    border: 1px solid #EBEBEB;
    border-radius: 3px 3px 3px 3px;
    margin: 15px;
    padding: 20px;
    width: 243px;
  }

  .blog-post.masonry-quote {
    margin: 15px;
    width: 285px;
  }

  #project-header {
    height: 400px;
  }

  #project-counters .counters {
    margin: 0 auto;
    width: 100%;
  }

  .recent-post .blog-meta {
    display:none;
  }

  .price-table {
    width:49%;
  }
}

@media only screen and (max-width: $hPhone) {
  .container {
    width:400px;
  }
}

@media only screen and (max-width: $vPhone) {
  .container {
    width:100%;
  }
  .section-title {
    width: 94%;
  }
  .text-slide-vertical {
    font-size:30px;
  }

  .text-slide-vertical li {
    line-height:40px;
  }
}
