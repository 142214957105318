@mixin sfo-list($width, $bulletColor, $textColor) {
  > ul {
    margin: 0 auto;
    width: $width;
    list-style-type: circle;

    > li {
      text-indent: -17px;
      padding-left: 20px;
      color: $textColor;

      &:before {
        width: 20px;
        content: "";
        background: url('../images/bullet.svg') no-repeat center;
        background-size: 100%;
        padding-right: 8px;
        margin-top: 30px;
        margin-right: 8px;
        color: $bulletColor;
        /*font-weight: bold;*/
      }
    }

    @media only screen and (max-width: $vPhone) {
      width: 96%;
      margin: 0 2%;
    }
  }
}
