/* Blog */

#blog-header {
	text-align:center;
	padding:80px 0 100px 0;
}

#blog {
	position:relative;
	width:100%;
}

#blog .container {
	margin-top:-50px;
	background-color:#FFF;
	top:-50px;
	padding:40px 40px 60px 40px;
	width:1100px!important;
	border-radius:3px;
}

#blog.masonry .container {
	margin-top:-50px;
	background-color:#FFF;
	top:-50px;
	padding:25px;
	width:1130px!important;
	border-radius:3px;
}

.blog-posts-content {
	width:800px;
	min-height:500px;
	float:left;
	margin-right:50px;
}

#blog.masonry .blog-posts-content {
	width:100%;
	min-height:500px;
	float:left;
	margin-right:50px;
}

.blog-post {
	float:left;
}

.blog-post.masonry {
	background-color:#f7f7f7;
	border:#ebebeb 1px solid;
	padding:20px;
	width:283px;
	margin:25px;
	border-radius:3px;
}

.blog-post.masonry-quote {
	margin:25px;
	width:325px;
}

.blog-post.masonry .post-content {
	width:100%;
}

.blog-post.masonry-quote .post-content {
	width:100%;
}

.type-date {
	width:50px;
	height:100px;
	margin-right:30px;
	float:left;
}

.blog-type {
	width:50px;
	height:50px;
	border-radius:3px;
	float:none;
}

.blog-date {
	text-align:center;
	text-transform:uppercase;
	width:50px;
	height:45px;
	padding-top:5px;
	background-color:#fff;
	float:none;
}

.post-content {
	width:720px;
	float:left;
}

.post-slider {
	margin-bottom:30px;
}

.blog-title {
	line-height:25px;
	margin-bottom:10px;
	text-transform:uppercase;
	color:#222222;
}

.blog-title a {
	text-transform:uppercase;
}

.blog-title a:hover {
}

.blog-meta {
	font-size:12px;
	line-height:13px;
	margin-bottom:20px;
}

.blog-border {
	height:2px;
	width:100px;
	background-color:#999999;
	opacity:0.8;
}

.blog-content {
	margin-top:35px;
	margin-bottom:20px;
}

.post-quote {
	padding:30px;
	border-radius:3px;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
}

.post-quote:hover {
}

.post-quote h3 {
	color:#fff;
	text-transform:uppercase;
	line-height:30px;
	margin-bottom:15px;
}

.post-quote p {
	color:#fff;
	opacity:0.5;
}

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 10px;
	height: 0;
	overflow: hidden;
	margin-bottom:30px;
}

.video-container iframe, .video-container object, .video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border:0;
}

hr {
	background-color:#e9e9e9;
	height:1px;
	width:100%;
	margin:60px 0;
	float:left;
	border:none!important;
}

.blog-content  h1, .blog-content  h2, .blog-content  h3, .blog-content  h4, .blog-content  h5 {
	text-transform:uppercase;
	color:#222222;
	margin-bottom:10px;
	margin-top:30px;
}

code {
	background-color: #F9F9F9;
	border: 1px solid #E9E9E9;
	clear: both;
	display: block;
	font: 12px "Andale Mono",Courier,"Courier New",monospace;
	margin-bottom: 20px;
	overflow: auto;
	padding: 0px 20px;
	margin:20px 0;
	white-space: pre;
	color:#222222;
}

blockquote {
	background-image: url("images/blockquote.png");
	background-size:21px 17px;
	background-position: left 8px;
	background-repeat: no-repeat;
	font-family: Georgia,"Times New Roman",Times,serif;
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
	padding-left: 35px;
}

.blog_comments {
	border-bottom:1px solid #dfdfdf;
	margin-bottom:60px;
	padding-top:20px;
}

.blog_comments h4 {
	color: #222222;
	margin-bottom: 40px;
	text-transform:uppercase;
}

.user_comment {
	background: url("images/user.png") no-repeat scroll 0 0 transparent;
	color: #222222;
	margin-bottom: 40px;
	padding-left: 100px;
}

.comment-date {
	color: #ABABAB;
	font-family: Georgia,"Times New Roman",Times,serif;
	font-style: italic;
	margin-bottom: 10px;
}

.comment-text {
	color: #999999;
}

.user_comment_reply {
	background: url("images/user.png") no-repeat scroll 0 0 transparent;
	color: #2B363C;
	margin-top: 40px;
	padding-left: 100px;
}

.comment_formular h4 {
	text-transform:uppercase;
	color:#222;
	margin-bottom:40px;
}

#commentsform input[type="text"], textarea {
	color: #999999;
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	text-transform:uppercase;
	height: 20px;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 90%;
	background:#f5f5f5;
	border-radius:3px;
	margin-bottom:20px;
	border:0;
	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	-moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	-webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	-o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
}

#commentsform input[type=text]:focus, #commentsform textarea:focus, input[type=email]:focus {
	background-color: #e1e1e1;
}

#commentsform textarea {
	height:90px;
	resize:none;
}

/* Posts Navigation */

.inner-navigation {
  margin-bottom:50px;
  margin-top:50px;
}

.inner-navigation.masonry {
  margin-left:50px;
  margin-right:50px;
  margin-bottom:50px;
  margin-top:50px;
}

.inner-navigation li a span {
  display:inline-block;
  margin-right:10px;
  width:30px;
  height:30px;
  border-radius:3px;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  float:left;
}

.inner-navigation li {
  display:inline-block;
  width:110px;
  line-height:27px;
}


.inner-navigation li:last-child a span {
  float:right;
  margin-left:10px;
  margin-right:0px;
}

.inner-navigation li:last-child {
  float:right;
}

.inner-navigation li a:hover span {
  color:#999;
}

.inner-navigation li a:hover {
  color:#999999;
}
