


/* Parallax Quote */


.quote h1 {
  color:#FFF;
  padding:30px 0 10px 0;
  line-height:40px;
  letter-spacing:0.5px;
}

.quote.quote-inverse h1 {
  color: #000;
}

.quote p {
  color:#FFF;
  opacity:0.5;
}

.quote.quote-inverse p {
  color: #000;
  opacity:0.5;
}


/*
h1 span.quote-img  {
}

h1 span.quote-img img {
  width:21px;
  height:auto;
  padding:10px;
  margin-bottom:12px;
  opacity:0.5;

}
*/
