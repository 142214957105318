/* Footer */


footer {
	box-shadow: 0px -1px 5px rgba(0,0,0,0.15);
	z-index:100;
	position:relative;
	padding:40px 0;
	text-align:center;
}

#menu_top {
	height:50px;
	width:40px;
	border-radius:3px;
	left:50%;
	margin-left:-20px;
	margin-top:-65px;
	position:absolute;
	cursor:pointer;
}

#menu_top_inside {
	background-image:url(images/menu_bottom_top_bg.png);
	background-size:18px 11px;
	background-position: 0 0;
	height: 22px;
	left: 11px;
	position: absolute;
	top: 14px;
	transition: background-position 0.3s linear 0s;
	width: 18px;
}

#menu_top:hover #menu_top_inside {
	-webkit-animation: bottom_top_over .3s linear infinite;
	-moz-animation: bottom_top_over .3s linear infinite;
	-o-animation: bottom_top_over .3s linear infinite;
	animation: bottom_top_over .3s linear infinite;
}

@-webkit-keyframes bottom_top_over { 0% { background-position: 0px 0px; }  100% { background-position: 0px -11px; } }
@-moz-keyframes bottom_top_over { 0% { background-position: 0px 0px; }  100% { background-position: 0px -11px; } }
@keyframes bottom_top_over { 0% { background-position: 0px 0px; }  100% { background-position: 0px -11px; } }











