section#real-estate {
  @include sfo-section-shade(50%);
  @include sfo-list(600px, $red, $white);

  > ul > li > ul {
    margin: 0 0 0 20px;
    > li {
      color: $white;
      &:before {
        content: "–";
        padding-right: 5px;
        color: $white;
      }
    }
  }
}
