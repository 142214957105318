section#map {
    .container {
      text-align: center;
    }

    .one-half {
      display: inline-block;
      width: 46%;
      height: 350px;
    }

    .left { margin-right: 1em; }
    .right { margin-left: 1em; }

  @media screen and (max-width: $hPhone) {
    display: none;
  }
}
