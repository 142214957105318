/* Project External */

#project-header {
	height:600px;
	z-index:1;
}

#project-header .container {
	height:inherit;
}

.table-header {
	display:table;
	position:relative;
	height:inherit;
	width:100%;
}

.table-left {
	width:50%;
	left:0;
	position:absolute;
	bottom:0;
}

.table-right {
	width:50%;
	text-align:left;
	position:relative;
	right:0;
	display:table-cell;
	vertical-align:middle
}

.table-header h2 {
	margin-bottom:25px;
}

.table-header p{
	margin-bottom:35px;
}

#project-url {
	height:40px;
	background-color:#222222;
	text-align:center;
	position:relative;
	z-index:10;
}

#project-url .site a {
	color: #FFFFFF;
	display: block;
	font-family: Georgia;
	font-size: 13px;
	height: 40px;
	letter-spacing: 1px;
	line-height: 40px;
	overflow: hidden;
}

#project-url .site .group {
	display: block;
	width: 100%;
}

#project-url .site .group span {
	display: block;
	height: 40px;
	transition:all 0.3s ease 0s;
	-moz-transition:all 0.3s ease 0s;
	-webkit-transition:all 0.3s ease 0s;
	-o-transition:all 0.3s ease 0s;
}

#project-url .site:hover .text {
	display: block;
	margin-top:-40px;
}

#project-url .site:hover .hover {
	display: block;
}

#project-info .section-title {
	margin-bottom:0;
}

#project-features {
	background-color:#fff;
}

#project-slider .container{
	padding-bottom:60px;
}

#project-slider .bx-wrapper {
	margin-bottom:60px!important;
}

#project-options {
	background:#fff;
}

#project-options h2 {
	margin:40px 0;
}

#project-options ul {
	margin-bottom:40px;
}

#project-options ul li {
	line-height:24px;
}

#project-counters {
	background-color:#222;
}

#project-counters .counters {
	margin-top:20px;
	width:1120px;
	margin:0 auto;
}

#project-counters .counters li {
	background: none ;
	border-radius: 3px 3px 3px 3px;
	color: rgba(255,255,255,0.5);
	font-family: 'Open Sans', serif;
	font-size: 14px;
	padding: 25px 0;
	text-align: center;
	text-transform: none;
}

#project-counters .counters li .count {
	border-radius: 3px 3px 3px 3px;
	color: #fff;
	font-family: 'Montserrat',sans-serif;
	font-size: 70px;
	padding: 25px 0;
	text-align: center;
	text-transform: uppercase;
	display:block;
}
