/* Accordion */

.accordion {
	float:left;
	width:inherit;
}

.accordion dt {
	cursor: pointer;
	border-radius: 3px 3px 3px 3px;
	margin-bottom: 10px;
	padding-top: 10px;
	border-top:#e9e9e9 1px solid;
	transition:all 0.4s ease 0s;
	-moz-transition:all 0.4s ease 0s;
    	-webkit-transition:all 0.4s ease 0s;
    	-o-transition:all 0.4s ease 0s;
}

.accordion dt:first-child {
	border-top:none;
	padding-top: 0px;
}

.accordion .accordion-active {
	color:#222222;
}

.accordion dt span {
	font-size: 14px;
	line-height:24px;
}

.accordion dt span.accordion-status {
	height:20px;
	width:20px;
	padding:2px;
	background:url(images/plus.png) #999999 2px 2px no-repeat;
	background-size:20px 20px;
	float:left;
	margin-right:10px;
	border-radius: 2px;
}

.accordion dt.accordion-active span.accordion-status {
	background:url(images/minus.png) 2px 2px no-repeat;
	background-color:#222222;
	background-size:20px 20px;
}

.accordion-content {
	padding-bottom: 15px;
	padding-top: 5px;
	font-size: 13px;
}