/* We are good at */

.bar {
	text-align: left!important;
	margin: 0px!important;
}

.bar li {
	list-style: none!important;
}

.bar li:last-child span {
	margin-bottom: 0px;
}

.bar li .bar-wrap {
	margin-bottom:50px;
	border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	-o-border-radius: 3px 3px 3px 3px;
	-moz-border-radius: 3px 3px 3px 3px;
	background:#dddddd;
}

.bar li span {
	height: 35px;
	width: 0px;
	display: block;
	border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	-o-border-radius: 3px 3px 3px 3px;
	-moz-border-radius: 3px 3px 3px 3px;
}

.bar li p {
	padding-bottom: 2px;
}

.bar li span {
	overflow: visible!important;
	position: relative;
}

.bar li span strong {
	position: absolute;
	top: 10px;
	left: 10px;
	color: rgba(255, 255, 255, 0.8);
	line-height: 12px!important;
	display: block;
}