/*  Counters  */
.counters {
	margin-top: 40px;
}

.counters ul {
	text-align:center;
}

.counters i {
	display: block;
	font-size: 50px;
	margin-bottom: 20px;
	position: relative;
}

.counters li {
	background:#FFF;
	color: #222222;
	font-family: 'Montserrat', sans-serif;
	font-size: 18px;
	padding: 25px 0;
	text-align: center;
	text-transform: uppercase;
	border-radius:3px
}

.counters li span {
	font-family: 'Montserrat', sans-serif;
}

.counters li.last {
	border-right: 0 none;
}


/*  List Styles  */


	.icons-ul li {
		color:#999999;
		margin-bottom:10px;
		font-size:14px;
	}

	.icons-ul li i{
		margin-right:7px;
	}

	.fa-ul {
		font-size:14px;
	}

	.fa-ul li {
		margin-bottom:10px;
	}