/* Recent Posts */

.recent-post {
	width:100%;
	height:50px;
	float:left;
	margin-bottom:20px;
	transition:all 0.3s ease 0s;
	-moz-transition:all 0.3s ease 0s;
  	-webkit-transition:all 0.3s ease 0s;
  	-o-transition:all 0.3s ease 0s;
	background-color:#FFF;
	border-radius:3px;
}

.recent-post:hover {
	background-color:#FFF;
	box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
}

.recent-post .recent-post-date {
	line-height:50px;
	float:left;
	margin-right:20px;
	margin-left:20px;
	font-size:12px;
}

.recent-post .blog-type {
	float:left;
	border-radius:0px;
	margin-right:20px;
	background-color:#999;
	transition:all 0.3s ease 0s;
	-moz-transition:all 0.3s ease 0s;
  	-webkit-transition:all 0.3s ease 0s;
  	-o-transition:all 0.3s ease 0s;
}

.recent-post:hover .blog-type {
	background-color:#222;
}

.recent-post h5 {
	line-height:50px;
	float:left;
}

.recent-post h5 a {
	color:#999;
}

.recent-post h5 a:hover {
	color:#222;
}

.recent-post .blog-meta {
	float:right;
	line-height:50px;
	margin-right:20px;
}