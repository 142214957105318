/* Sidebar */

#sidebar {
	width:250px;
	min-height:500px;
	float:left;
}

.widget {
	margin-bottom:60px;
	font-size:14px;
}

.heading h5 {
	margin-bottom:20px;
	text-transform:uppercase;
	color: #222222;
}

input#search {
	background:url("images/zoom-search.png") no-repeat 10px 10px #f5f5f5;
	background-size:30px 30px;
	color: #999999;
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	text-transform:uppercase;
	height: 20px;
	padding: 15px;
	padding-left:45px;
	width: 190px;
	border-radius:3px;
	border:0;
	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	-moz-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	-webkit-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	-o-box-shadow: 0 0px 3px rgba(0, 0, 0, 0.22) inset;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
}

input#search:focus {
	background:url("images/zoom-search.png") no-repeat 10px 10px #e1e1e1;
	background-size:30px 30px;
}

#sidebar .widget_categories li {
	background: url("images/arrow-li.png") no-repeat scroll 0 22px transparent;
	background-size:5px 8px;
	border-bottom: 1px solid #dfdfdf;
	display: block;
	padding: 15px;
}

#sidebar .widget_categories li:first-child {
	border-top: 1px solid #dfdfdf;
}

#sidebar .widget_categories li a {
	color: #222222;
}

#sidebar .widget_categories li a:hover {
}

#sidebar .recent-posts li {
	border-top: 1px solid #dfdfdf;
	padding-top:12px;
}

#sidebar .recent-posts p {
	padding-bottom:12px;
	font-size:12px;
}

#sidebar .recent-posts {
	border-bottom: 1px solid #dfdfdf;
}

#sidebar .recent-posts li a {
	color: #222222;
}

#sidebar .recent-posts li a:hover {
}

#sidebar .tags a {
	border-radius: 2px 2px 2px 2px;
	background-color:#222222;
	color: #FFFFFF !important;
	cursor: pointer;
	display: inline-block;
	float: left;
	font-size: 12px;
	margin: 5px 5px 0 0;
	padding: 7px 10px;
	position: relative;
	text-transform: capitalize;
}

#sidebar .tags a:hover {
}

#sidebar .widget_archives li {
	background: url("images/arrow-li.png") no-repeat scroll 0 22px transparent;
	background-size:5px 8px;
	border-bottom: 1px solid #dfdfdf;
	display: block;
	padding: 15px;
}

#sidebar .widget_archives li:first-child {
	border-top: 1px solid #dfdfdf;
}

#sidebar .widget_archives li a {
	color: #222222;
}

#sidebar .widget_archives li a:hover {
}