/* We love technology */

ul.technology {
  text-align:center;
}

ul.technology li {
  display:inline-block;
  width:238px;
  margin:10px;
  padding:30px 20px;
  position:relative;
    overflow:hidden;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

ul.technology li:hover:before {
   content:"";
   position:absolute;
   top:0;
   right:0;
   border-width:0 25px 25px 0;
   border-style:solid;
   display:block; width:0;
}

ul.technology li:first-child, ul.technology li:last-child {
  margin-left:0px;
}

ul.technology li:last-child {
  margin-right:0px;
}

ul.technology li i {
  font-size:40px;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

ul.technology li h5 {
  text-transform:uppercase;
  color:#222222;
  margin-top:10px;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

ul.technology li p {
  font-size:13px;
  margin-top:10px;
  transition:all 0.3s ease 0s;
  -moz-transition:all 0.3s ease 0s;
    -webkit-transition:all 0.3s ease 0s;
    -o-transition:all 0.3s ease 0s;
}

ul.technology li:hover i {
  color:#fff;
}

ul.technology li:hover h5 {
  color:#fff;
}

ul.technology li:hover p {
  color:#fff;
  opacity:0.5
}
