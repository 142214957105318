.one_half {
  	width: 48%;
}

.one_third {
	width: 30.6%;
}

.one_fourth {
	width: 22%;
}

.one_fifth {
	width: 16.8%;
}

.one_sixth {
	width: 13.33%;
}

.two_fifth {
	width: 37.6%;
}

.two_fourth {
	width: 48%;
}

.two_third {
	width: 65.33%;
}

.three_fifth {
	width: 58.4%;
}

.three_fourth {
	width: 74%;
}

.four_fifth {
	width: 79.2%;
}

.five_sixth {
	width: 82.67%;
}

.one_half, .one_third, .two_third, .three_fourth, .one_fourth, .two_fourth, .one_fifth, .two_fifth, .three_fifth, .four_fifth, .one_sixth, .five_sixth {
	float: left;
	margin-bottom: 20px;
	margin-right: 4%;
	position: relative;
}

.last {
	margin-right:0px!important;
}