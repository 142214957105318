html,body,div,span,h1,h2,h3,h4,h5,h6,p,blockquote,a,img,small,sub,b,dl,dd,dt,ul,li,label,article,details,footer,header,menu,nav,input,textarea,select{
	background:transparent;
	border:0;
	outline:0;
	padding:0;
	margin:0;
}


html,body{
	font-family: 'Open Sans', sans-serif;
	font-size:12px;
	height:100%;
	width:100%;
	color:#999999;
	background:#f5f5f5;
}

body{
	overflow-x:hidden
}

/* sections */

div.section-em {
  background-color: $grey;
}

section.section-em-red,
div.section-em-red {
  background-color: #ae1325;
}

div.section-em .container,
div.section-em-red .container {
  padding: 20px 20px;
}

.uppercase {
  text-transform: uppercase;
}

.red {
  color: #8A0514;
}

section {
  padding: 20px 0 40px 0;
}

section.inverse h1 {
  color: #FFF;
}

section.inverse .section-title p {
  color: #ae1325;
  text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  text-transform:uppercase;
  color:#222222;
}

h1 {
  font-size:36px;
  letter-spacing:3px;
  color:#222222;
  text-transform:uppercase;
  margin-bottom: 15px;
}

p {
  font-size:14px;
}

b {
  color:#222;
}

.container {
  width:1180px;
  margin:0 auto;
}

.screen {
  padding: 100px 20px;
}

.screen-small {
  padding: 40px 20px;
}

.screen-smaller {
  padding: 20px 20px;
}

.small-width {
  width:980px;
}

.no-padding {
  padding:0!important;
}

a:hover, a:active, a:focus {
  outline: 0;
}

a {
      text-decoration: none;
      -webkit-transition: background-color .3s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline .3s ease;
         -moz-transition: background-color .3s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline .3s ease;
          -ms-transition: background-color .3s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline .3s ease;
           -o-transition: background-color .3s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline .3s ease;
              transition: background-color .3s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline .3s ease;
  }

li {
  list-style:none;
}

a {
  outline: 0;
}

a:hover {
  text-decoration:none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.section-title {
  text-align:center;
  width:700px;
  margin:0 auto;
  margin-bottom: 20px;
}

.border {
  background: none repeat scroll 0 0 #999999;
  display: block;
  height: 2px;
  margin: 0 auto;
  width: 100px;
  margin-bottom:40px;
}

.title-divider {
  border-bottom:#e9e9e9 1px solid;
  width:100%;
  text-align:center;
  text-transform:uppercase;
  color:#222222;
  padding-bottom:5px;
  padding-top:80px;
  margin-bottom:50px;
  float:left;
}

@media only screen and (max-width: $vPhone) {
  div.section-em .container, div.section-em-red .container {
    padding: 20px 0;
  }
  .screen {
    padding: 30px 0;
  }
  .screen-small {
    padding: 40px 0;
  }
  .screen-smaller {
    padding: 20px 2%;
  }
}


