a {
	color: #999999;
}

a:hover {
	color: #222222;
}

.grey {
	background-color:#222222;
}

.grey:hover {
	background-color:#999999;
}

.color {
	background-color:#222222;
}

.color:hover {
	background-color:#999999;
}

.parallax-overlay.parallax-background-color {
	background-color:rgba(0,0,0,0.80);
}

span.text-color {
	color:#fff;
}

.bar li span { 
	background-color:#222222;
}

.service-item.text-right .fa-stack {
	color:#999999;
}

.service-item.text-left .fa-stack {
	color:#999999;
}

ul.technology li i {
	color:#999999;
}

ul.technology li:hover {
	background-color:#222222;
}
	
ul.technology li:hover:before {
	border-color:#fff #fff #4e4e4e #4e4e4e;
}

#filters ul li a:hover h5, #filters ul li a.active h5 {
	background-color:#222222;
}

.project-overlay {
	background-color:#222222;
}

#contact-formular input[type="submit"]	{
	background:#222222;
}

#contact-formular input[type="submit"]:hover	{
	background:#999999;
}

#menu_top {
	background-color:#222222;
}

.blog-title a {
	color:#222222;
}

.blog-title a:hover {
	color:#999999;
}

.blog-type {
	background-color:#222222;
}

.post-quote {
	background-color:#222222;
}

.post-quote:hover {
	background-color:#999999;
}

#sidebar .widget_categories li a:hover {
	color: #999999;
}

#sidebar .recent-posts li a:hover {
	color: #999999;
}

#sidebar .tags a:hover {
	background-color:#999999;
}

#sidebar .widget_archives li a:hover {
	color: #999999;
}

.inner-navigation li a:hover span {
	background-color:#999999;
}

.inner-navigation li a span {
	background-color:#222222;
}

.accordion dt.accordion-active span.accordion-status {
	background-color:#222222;
}

.toggle-active {
	background: #222222;
}

ul.tabs .tab-active a { 
	background-color: #222; 
}

.dropcap-normal {
	color: #222222;
}

.dropcap.dark {
	background-color: #222;
}

.counters i {
	color: #999999;
}

.fa-ul li i{
	color:#222222;
}

.the-icons li i {
	color: #222222;
}

.price-table i {
	color: #999;
}

.price-table:hover i {
	background-color: #222;
	border-color: #222;
}

#newsletter {
	background-color:#222;
}