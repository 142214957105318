
	#logo {
		background:url(../images/logo.svg) 0 12px no-repeat;
		background-size: 120px auto;
		position:absolute;
		width:120px;
		height:50px;
		left:30px;
		margin-left:0;
		z-index:100;
		display:block;
		transform-style: preserve-3d;
		transition: -moz-transform 0.5s ease 0s;
	}

	#logo a {
		width:120px;
		height:50px;
		position:absolute;
	}

	.logo-back {
		transform: rotateY(-180deg);
	}

	#navigation-sticky-wrapper {
		height:45px;
	}

	.navbar-inner {
		background: none;
		box-shadow: none !important;
		min-height: 65px;
		line-height: 40px !important;
		transition: all 0.3s ease-out 0s;
		border-bottom: none !important;
		padding-left: 20px !important;
		padding-right: 20px !important;
		background: #222222;
		box-shadow: 0px 2px 5px rgba(0,0,0,0.1)!important;
		z-index:10;
	}

	.navbar-inner .container {
		padding:20px 0;
	}

	.navbar {
		position: relative;
	}

	.navbar .nav {
		width:100%;
		text-align:right;
	}

	.navbar .nav > li {
		float:none;
		display:inline-block;
	}

	.navbar .nav > li.menu-1 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-2 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-3 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-4 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-5 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-6 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-7 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-8 {
		margin-right:0px;
	}

	.navbar .nav > li > a {
		text-transform:uppercase;
		font-weight:400;
		font-size:12px;
		color: #999999;
		display: block;
		height: 100%;
		transition: all 0.3s ease-out 0s;
		line-height: 45px;
		text-shadow: none;
	}

	.navbar .nav > .active > a, .navbar .nav > .active > a:hover, .navbar .nav > .active > a:focus {
		background: none;
		box-shadow: none;
		color: #fff;
		height: 100%;
		transition: all 0.3s ease-out 0s;
	}

	.navbar .nav > li > a:focus, .navbar .nav > li > a:hover {
		color: #fff;
		text-decoration: none;
		height: 100%;
		transition: all 0.3s ease-out 0s;
	}

	.navbar .show-menu {
		float: right;
		width:30px;
		margin: 15px 30px 15px 10px;
		height: 30px;
		padding:2px;
		background:url(../images/responsive-menu1.png) no-repeat 2px 2px;
		background-size:30px 30px;
		cursor:pointer;
		border-radius:3px;
		opacity:0.7;
		display:none
	}

	.navbar .show-menu:hover {
		opacity:1;
	}

@media only screen and (max-width: $desktop) {

	.navbar .nav > li.menu-1 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-2 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-3 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-4 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-5 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-6 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-7 {
		margin-right:10px;
	}

	.navbar .nav > li.menu-8 {
		margin-right:0px;
	}
}

@media only screen and (max-width: $tablet) {

	.navbar-inner {
		min-height: 45px!important;
		padding:0!important;
	}

	.nav-collapse {
		background-color:#f5f5f5;
		padding:0px 30px;
	}

	.navbar .nav > li {
		display: inline-block;
		float: left;
		width:100%;
		border-bottom: 1px dotted #CCCCCC;
	}

	.navbar .nav > li:last-child {
		border-bottom:none;
	}

	.navbar .nav {
		text-align: left;
		width: 100%;
	}

	.navbar .nav > li > a {
		padding: 8px 0px;
		font-family: 'Open Sans',serif;
		font-size:12px;
		line-height:25px;
	}

	.is-sticky .navbar .nav > li > a {
		line-height:25px;
	}

	.navbar .nav > .active > a, .navbar .nav > .active > a:hover, .navbar .nav > .active > a:focus {
		color: #222222;
	}

	.navbar .nav > li > a:focus, .navbar .nav > li > a:hover {
		color: #222222;
	}

	.navbar .show-menu {
		display:block;
	}

	.navbar .nav-collapse .nav.pull-right {
		float: left;
		margin:10px 0;
	}
}


@media only screen and (max-width: $hPhone) {

}

@media only screen and (max-width: $vPhone) {

}
