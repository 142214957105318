section.wealth {

  @include sfo-section-shade(50%);
  @include sfo-header-bubble(160px);

  > ul {
    list-style: inherit;
    list-style-type: square;
    > li {
      margin:0 20px;
      height: 380px;
      width:220px;
      padding: 40px 20px 10px 20px;

      @include sfo-list(100%, $red, #4f4f4f);
    }
    > li.asset-management {
      @media only screen and (max-width: $hPhone) {
        margin-top: 180px;
      }
    }
  }

  .result {
    img {
      width: 30%;
      margin: -40px auto 0;
    }

    position: relative;
    background-color: $grey;
    padding: 10px 20px 10px;
    margin: 20px -20px;
    color: #444;

    @include sfo-list(100%, $red, #444);

  }

  @media only screen and (max-width: $desktop) {}
  @media only screen and (max-width: $tablet) {}
  @media only screen and (max-width: $vPhone) {}

}
