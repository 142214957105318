.element_from_top {
	top: -50px;
	padding-right: 0px;
	opacity: 0;
	position: relative;
}

.element_from_bottom {
	bottom: -50px;
	padding-left: 0px;
	opacity: 0;
	position: relative;
}

.element_from_left {
	left: -80px;
	padding-right: 0px;
	opacity: 0;
	position: relative;
}

.element_from_right {
	right: -80px;
	padding-left: 0px;
	opacity: 0;
	position: relative;
}

.element_fade_in {
	opacity: 0;
	position: relative;
	padding-left: 0px;
	right: 0px;
}