/* Values */

section#about {
  .our-values {
    text-align:center;
  }

  .our-values li {
    display:inline-block;
    margin:0 20px;
    height: 140px;
    overflow:hidden;
    border-radius:0px;
    position:relative;
    width:140px;
    background-color: white;
    padding: 40px 20px 10px 20px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      border-width: 0 16px 16px 0;
      border-style: solid;
      border-color: #949494 $grey;
      /* for firefox 3.0 */
      display: block;
      width: 0;
      /* shadow
      -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.4);
      -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.4);
      box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.4);
      */
    }
  }

  .our-values__section {
    p {
      color: #4f4f4f;
    }
  }

  @media only screen and ($desktop) {}
  @media only screen and ($tablet) {}
  @media only screen and ($hPhone) {}
  @media only screen and ($vPhone) {}


}


